import React, { useState } from 'react';
import './form.css';
import { IoArrowForward } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { NumberWithCommas, formatDate } from '../../../utils';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { Divider } from '@mui/material';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';

const Table = ({
    Data,
    disp_invoice_products,
    disp_view_invoice,
    setamountToPay
}) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const data = Data

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedIds(data.map((row) => row.id));
        } else {
            setSelectedIds([]);
        }
    };

    const handleSelectRow = (event, id) => {
        if (event.target.checked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const currentData = data && data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return (
        <div className="table-container"  >
            {/* {console.log(data)} */}
            <table style={{ width: "100%", }} >
                <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <Divider />
                <tbody>
                    {currentData.map((items) => {
                        const InvoiceProductAmount = items.product ? items.product.reduce((acc, item) => acc + item.totalCost * item.qty, 0) : 0;
                        return <>
                            <tr key={items.id}>

                                <td >{NumberWithCommas(InvoiceProductAmount)}</td>
                                <td >{formatDate(items.created_at)}</td>
                                <td>{items.paid ?
                                    <b style={{ color: "mediumseagreen" }} >Paid</b> : <b style={{ color: "crimson" }} >Not Paid</b>}</td>
                                <td
                                    onClick={() => {
                                        // Initialize sum variable
                                        let sum = 0;

                                        // Iterate through each object in the array
                                        for (let i = 0; i < items.product.length; i++) {
                                            // Add the price of the current object to the sum
                                            sum += items.product[i].totalCost;
                                        }
                                        // console.log(items.id)


                                        disp_invoice_products({
                                            ...items,
                                            vat: parseInt(sum * 0.75),
                                            totalPrice: sum,
                                            product: items.product,
                                            paid: items.paid,
                                            id: items.id,
                                            invoiceID: items.invoiceID
                                        }) 

                                        disp_view_invoice(true)
                                        setamountToPay(sum + (sum * 7.5 / 100)) 
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        color: "#252C58", 
                                    }}
                                >
                                    View <FaArrowAltCircleRight />
                                </td> 

                            </tr>
                        </>
                    })}

                </tbody>
            </table>

            {currentData && currentData.length < 1 && <>
                <div style={{
                    height: 80,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <h2>No record found.</h2>
                </div>
            </>}


            <Divider />
            <div className="pagination-controls" style={{ padding: 20 }} >
                <label>
                    <small> Rows per page:</small>
                    <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </label>
                <div style={{
                    justifyContent: "center", alignItems: "center", display: "flex"
                }} >
                    <small style={{ marginRight: 40 }} >
                        {currentPage} of {totalPages}
                    </small>
                    <span style={{ marginRight: 40, cursor: currentPage === 1 ? "no-drop" : "pointer", opacity: currentPage === 1 ? 0.4 : 1 }} onClick={() => handleChangePage(Math.max(currentPage - 1, 1))}>
                        <BiArrowToLeft />
                    </span>
                    <span onClick={() => handleChangePage(Math.min(currentPage + 1, totalPages))} style={{ marginRight: 40, cursor: currentPage === totalPages ? "no-drop" : "pointer", opacity: currentPage === totalPages ? 0.4 : 1 }} >
                        <BiArrowToRight />
                    </span>

                </div>
            </div>
        </div>
    );
};

export default Table;