import React, { useState } from 'react';
import './form.css';
import { IoArrowForward } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { Divider } from '@mui/material';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';
import { NumberWithCommas, formatDate } from '../utils';

const InvoiceTable = ({
    Data,
    disp_view_invoice,
    disp_invoice_products,
    setamountToPay
}) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const data = Data

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedIds(data.map((row) => row.id));
        } else {
            setSelectedIds([]);
        }
    };

    const handleSelectRow = (event, id) => {
        if (event.target.checked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const currentData = data && data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return (
        <div className="table-container"  >
            {/* {console.log(data)} */}
            <table style={{ width: "100%", }} >
                <thead>
                    <tr>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Amount</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Date</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Status</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Action</th>
                    </tr>
                </thead>
                <Divider />

                <tbody style={{
                    alignItems:"flex-start"
                }} >
                    {currentData.map((item) => {
                        const InvoiceProductAmount = item.product ? item.product.reduce((acc, items) => acc + items.totalCost * items.qty, 0) : 0;
                        return <>
                            <tr key={item.id}>
                                <td style={{
                                    fontSize: 11,
                                    fontWeight: 500,
                                    padding:20
                                    // backgroundColor: "red",
                                    // width: "40%"
                                }}> {NumberWithCommas(InvoiceProductAmount)} </td>
                                <td style={{
                                    fontSize: 11,
                                    fontWeight: 500,
                                    // backgroundColor: "red",
                                    // width: "60%"
                                }}>{formatDate(item.created_at)}</td>
                                <td style={{
                                    fontSize: 11,
                                    fontWeight: 500,
                                    // backgroundColor: "red",
                                    // width: "30%"
                                }}>{item.paid ?
                                    <b style={{ color: "mediumseagreen" }} >Paid</b> : <b style={{ color: "crimson" }} >Not Paid</b>}</td>
                                <td
                                    onClick={() => {
                                        // Initialize sum variable
                                        let sum = 0;

                                        // Iterate through each object in the array
                                        for (let i = 0; i < item.product.length; i++) {
                                            // Add the price of the current object to the sum
                                            sum += item.product[i].totalCost;
                                        }
                                        // console.log(items.id)

                                        disp_invoice_products({
                                            ...item,
                                            vat: parseInt(sum * 0.75),
                                            totalPrice: sum,
                                            product: item.product,
                                            paid: item.paid,
                                            id: item.id,
                                            invoiceID: item.invoiceID
                                        })
                                        disp_view_invoice(true)
                                        setamountToPay(sum + (sum * 7.5 / 100))

                                        // console.log(sum + (sum * 7.5 / 100))
                                        // console.log(items)
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        color: "#252C58",
                                        fontSize: 11,
                                        fontWeight: 500,
                                        // backgroundColor: "red",
                                        // width: "50%",
                                        textAlign: "center"
                                    }} >View <FaArrowAltCircleRight /> </td>
                            </tr>
                        </>
                    })}
                </tbody>

            </table>


            {currentData.length < 1 &&
                <div style={{
                    // backgroundColor: "red",
                    width: "100%",
                    height: 100
                }} >
                    <center>
                        <b>No record found</b>
                    </center>
                </div>
            }


            <Divider />
            <div className="pagination-controls" style={{ padding: 20 }} >
                <label>
                    <small> Rows per page:</small>
                    <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                        {/* <option value={5}>5</option> */}
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                    </select>
                </label>
                <div style={{
                    justifyContent: "center", alignItems: "center", display: "flex"
                }} >
                    <small style={{ marginRight: 40 }} >
                        {currentPage} of {totalPages}
                    </small>
                    <span style={{ marginRight: 40, cursor: currentPage === 1 ? "no-drop" : "pointer", opacity: currentPage === 1 ? 0.4 : 1 }} onClick={() => handleChangePage(Math.max(currentPage - 1, 1))}>
                        <BiArrowToLeft />
                    </span>
                    <span onClick={() => handleChangePage(Math.min(currentPage + 1, totalPages))} style={{ marginRight: 40, cursor: currentPage === totalPages ? "no-drop" : "pointer", opacity: currentPage === totalPages ? 0.4 : 1 }} >
                        <BiArrowToRight />
                    </span>

                </div>
            </div>
        </div>
    );
};

export default InvoiceTable;