import React, { useState } from 'react'
import Sidebar from '../../Components/Sidebar'
import DashHeader from '../../Components/DashHeader'
import { Notify, NumberWithCommas } from '../../utils'
import { SaveInvoiceModel, fetchAllProducts, fetchAllProductsXX, saveProductsModels } from '../../service/supabase-service'
import { connect } from 'react-redux'
import { Invoice_Product, Products, View_invoice } from '../../redux/state/action'
import { Link, useNavigate } from 'react-router-dom'
import { CircularProgress, Divider } from '@mui/material'
import logo from '../../images/logo.jpeg'
import AdminSidebar from '../../Components/admin-sidebar'
import { IoFilterSharp } from 'react-icons/io5'
import Modal from "react-modal";
import { ToastContainer } from 'react-toastify'
import Table from './components/product-table'



const Productmgt = ({
    disp_products, disp_view_invoice,
    appState, disp_invoice_products
}) => {
    const AllProducts = appState.AllProducts;
    const User = appState.User;
    const InvoiceProducts = appState.AllInvoiceProducts


    const [price, setprice] = useState("")
    const [type, settype] = useState("")
    const [treshold, settreshold] = useState("")
    const [expiry, setexpiry] = useState("")
    const [name, setname] = useState("")
    const [category, setcategory] = useState("")
    const [quantity, setquantity] = useState(1)
    const navigate = useNavigate();
    const [loading, setloading] = React.useState(false)
    const [TableData, setTableData] = React.useState(AllProducts && AllProducts.filter(e => e.type == "SERVICE"))
    // const [TableData, setTableData] = React.useState(AllProducts && AllProducts)
    const uniqueCategories = [...new Set(AllProducts && AllProducts.filter(e => e.category != "Products").map(item => item.category))];

    function GetAllProducts() {
        // fetchAllProductsXX()
        fetchAllProducts()
            .then(response => {
                setloading(false)
                disp_products(response.data)
            })
            .catch(error => {
                setloading(false)
            })
    }


    function AddProduct() {
        // setloading(true)
        // setIsModalOpen(false);

        // if (!name || !category || !type || !price || !quantity || !expiry) {
        //     alert("Fill out all forms")
        // } 


        saveProductsModels({
            name, category, type, metaData: {
                price, quantity, expiry,
            }
        })
            .then(response => {

                if (response.error) {
                    return Notify(response.error.message, true)
                }
                console.log(name)
                Notify("Product added successfully")
                // GetAllProducts()

            })
            .catch(error => {
                setloading(false)
                // return res.send(ErrorObject("An error occured"))
            })
    }


    React.useEffect(() => {
        GetAllProducts()
    }, []);




    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };



    return (
        <div>
            <ToastContainer />

            {loading && <div style={{
                position: "fixed",
                height: "100%",
                width: "100%",
                left: 0,
                top: 0,
                backgroundColor: "rgb(0,0,0,0.8)",
                zIndex: 100,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
            }} >
                <CircularProgress />
                <spam style={{ color: "white" }} >Please wait.....</spam>
            </div>}


            {/* {console.log(AllProducts)} */}
            <section className='main-dash'>

                <AdminSidebar />

                <div className='main'>
                    <DashHeader User={User} />

                    <section className='products'  >

                        <div className="product-top">
                            <div className="product-c">
                                <h2>Overall Inventory</h2>

                                <div className="prod1">
                                    <h3 style={{ color: '#1570EF' }}>All products</h3>
                                    <h6>{AllProducts && AllProducts.length}</h6>
                                    {/* <p>Last 7 days</p> */}
                                </div>

                                <div className="prod1 prod2">
                                    <div className="p2-c">

                                        <h3 style={{ color: "#E19133" }}>Woji Branch</h3>
                                        <div className='p-f1'>
                                            <h6>{AllProducts && AllProducts.filter(e => e.type == "SERVICE").length}</h6>
                                            <h6>{AllProducts && AllProducts.filter(e => e.type == "PRODUCT").length}</h6>
                                        </div>

                                        <div className='p-f1'>
                                            <p>Products</p>
                                            <p>Services</p>
                                        </div>

                                    </div>

                                </div>

                                <div className="prod1 prod2">

                                    <div className="p2-c">

                                        <h3 style={{ color: '#845EBC' }}>GRA Branch</h3>
                                        <div className='p-f1'>
                                            <h6>{AllProducts && AllProducts.filter(e => e.type == "SERVICE").length}</h6>
                                            <h6>{AllProducts && AllProducts.filter(e => e.type == "PRODUCT").length}</h6>
                                        </div>

                                        <div className='p-f1'>
                                            <p>Products</p>
                                            <p>Services</p>
                                        </div>

                                    </div>

                                </div>


                                {/* <div className="prod1 prod2">
                                    <div className="p2-c">

                                        <h3 style={{color:'#F36960'}}>Low Stocks</h3>
                                        <div className='p-f1'>
                                            <h6>2</h6>
                                            <h6>12</h6>
                                        </div>

                                        <div className='p-f1'>
                                            <p>Ordered</p>
                                            <p>Not in stock</p>
                                        </div>

                                    </div>
                                    
                                </div> */}

                            </div>


                        </div>

                        <div className="product-table" style={{
                            // backgroundColor: "black",
                            // width: "100%"
                        }}
                        >
                            <div className="p-t-c" style={{
                                // backgroundColor: "green",
                                // width: "30%"
                            }} >

                                <div className="p-top" style={{
                                    // backgroundColor: "green",
                                    width: "100%"
                                }} >
                                    {/* <h2>Products</h2> */}
                                    <label>
                                        <small> Showing All : </small>
                                        <select
                                            style={{ width: "140px", padding: "4px 10px", borderRadius: "6px", marginLeft: "10px" }}
                                            onChange={(item) => {
                                                let newData = AllProducts && AllProducts.filter(e => e.type == item.target.value)
                                                setTableData(newData)

                                            }}>
                                            <option value="SERVICE">Services ({AllProducts.filter(e => e.type == "SERVICE").length}) </option>
                                            <option value="PRODUCT">Products ({AllProducts.filter(e => e.type == "PRODUCT").length}) </option>
                                        </select>
                                    </label>




                                    <div className='p-btns'>
                                        <button style={{ cursor: 'pointer' }} onClick={toggleModal}>Add Product</button>
                                        {/* <button className='filter'>
                                            <IoFilterSharp className='fil'/>
                                            Filters
                                        </button> */}
                                        {/* <button className='filter'>See All</button> */}
                                    </div>
                                </div>

                                <Table
                                    Data={AllProducts ? TableData : []}
                                />
                                {/* <table style={{ 
                                    width: "100%"
                                }} >
                                    <tr>
                                        <th flex={1} >Products</th>
                                        <th>Price</th> 
                                        <th>Type</th> 

                                    </tr>


                                    {
                                        AllProducts && AllProducts.slice(0, 10).map((Product, id) => {
                                            const statusColor = getStatusColor(Product.status);
                                            return (
                                                <tr key={id}>

                                                    <td>{Product.name}</td>
                                                    <td>{Product.metaData.price}</td> 
                                                    <td>{Product.type}</td> 

                                                </tr>
                                            )
                                        })
                                    }



                                </table> */}

                            </div>
                        </div>



                    </section>



                </div>


                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={toggleModal}
                    contentLabel="Example Modal"
                    className={`bg-transparnt`}
                    style={{
                        overlay: {
                            position: "fixed",
                            top: "0",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "hsla(0, 0%, 0%, .8)",
                            zIndex: 100000,

                        },
                    }}
                >
                    <div className='modal1'>
                        <div className='modal1-content'>
                            <div className='close'>
                                <button onClick={() => setIsModalOpen(false)} style={{ cursor: 'pointer' }}>X</button>
                            </div>

                            <div className='product-form'>

                                <div>
                                    <p>Product Name</p>
                                    <input type="text" value={name}
                                        onChange={(e) => {
                                            setname(e.target.value);
                                        }}
                                        placeholder='Enter name'
                                    />
                                </div>

                                <div>
                                    <p>Price</p>
                                    <input type="text"
                                        onChange={(e) => {
                                            setprice(e.target.value);
                                        }}
                                        placeholder='Enter price' value={price} />
                                </div>



                                <div>
                                    <p>Type</p>
                                    <select
                                        onChange={(e) => {
                                            settype(e.target.value);
                                        }}
                                    >
                                        <option value={null}>[select type]</option>
                                        <option value="SERVICE">Service</option>
                                        <option value="PRODUCT">Product</option>
                                    </select>
                                </div>



                                <div>
                                    <p>Catergory</p>
                                    <select name="" id="" onChange={(e) => {
                                        setcategory(e.target.value);
                                    }}>
                                        <option value={null}>[select Category]</option>

                                        {type == "PRODUCT" ? <>
                                            <option value="Maisonwellness Product">Maisonwellness Product</option>
                                        </> : <>
                                            <option value="Barbing Saloon"> Barbing Saloon</option>
                                            <option value="Massage (SPA)"> Massage (SPA)</option>
                                            <option value="NAIL SALON "> NAIL SALON </option>
                                            <option value="Pedi / Mani"> Pedi / Mani</option>
                                            <option value="Semi-permanent Brows">Semi-permanent Brows </option>
                                            <option value="Maison Package(s)">Maison Package(s) </option>
                                            <option value="Hammah (SPA)">Hammah (SPA)</option>
                                            <option value="Lash Extensions">Lash Extensions</option>
                                            <option value="Waxing (SPA)">Waxing (SPA)</option>
                                            <option value="Spa & Facials">SPA</option>


                                            <option value="BROWS AND LASHES">BROWS AND LASHES</option>
                                            <option value="HAIR SALONS">HAIR SALONS</option>
                                        </>}

                                    </select>
                                </div>

                                <div>
                                    <p>Quantity</p>
                                    <input type="text" value={quantity}
                                        onChange={(e) => {
                                            setquantity(e.target.value);
                                        }}
                                    />
                                </div>

                                <div>
                                    <p>expiry</p>
                                    <input type="date"
                                        onChange={(e) => {
                                            setexpiry(e.target.value);
                                        }}
                                        placeholder="Expiray date" value={expiry} />
                                </div>


                                <div className='btn'>
                                    <button onClick={() => {
                                        AddProduct()
                                    }} type="submit">Add producr</button>
                                </div>

                            </div>


                        </div>

                    </div>

                </Modal>

            </section>

        </div>


    )
}


const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_products: (payload) => dispatch(Products(payload)),
        disp_invoice_products: (payload) => dispatch(Invoice_Product(payload)),
        disp_view_invoice: (payload) => dispatch(View_invoice(payload)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Productmgt); 