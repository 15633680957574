import React, { useState } from 'react';
import './form.css';
import { IoArrowForward } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { NumberWithCommas, formatDate } from '../../../utils';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import { Divider } from '@mui/material';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';

const Table = ({
    Data,
    handleOpen,
    disp_invoice_products,
    InvoiceProductAmount
}) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const data = Data

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedIds(data.map((row) => row.id));
        } else {
            setSelectedIds([]);
        }
    };

    const handleSelectRow = (event, id) => {
        if (event.target.checked) {
            setSelectedIds([...selectedIds, id]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const totalPages = Math.ceil(data.length / rowsPerPage);
    const currentData = data && data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return (
        <div className="table-container"  >
            {/* {console.log(data)} */}
            <table style={{ width: "100%", }} >
                <thead>
                    <tr>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Type</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Amount</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Item(s)</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Customer type</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Date</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Sales rep</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Branch</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Status</th>
                        <th style={{ fontWeight: 900, fontSize: 12 }} >Action</th>
                    </tr>
                </thead>
                <Divider />
                <tbody>
                    {currentData.map((item) => {
                        const InvoiceProductAmount = item.product ? item.product.reduce((acc, items) => acc + items.totalCost * items.qty, 0) : 0;
                        return <>
                            <tr key={item.id}>
                                <td style={{ paddingLeft: 10 }} ><small>{item.type}</small></td>
                                <td><small>₦{NumberWithCommas(InvoiceProductAmount)}</small></td>
                                <td><small>{item.product.length}</small></td>
                                <td><small>{item.marketerid ? "Referred" : "Walk-in"}</small></td>
                                <td><small>{formatDate(item.created_at)}</small></td>
                                <td><small>{item.generated_by.split(" ")[0]}</small></td>
                                <td><small>{item.branch}</small></td>
                                <td className='av' style={{ color: item.paid == true ? "green" : "crimson" }} >{item.paid == true ? <small>PAID</small> : <small>NOT PAID</small>}</td>
                                <td
                                    onClick={() => {
                                        handleOpen()
                                        disp_invoice_products(item)
                                        console.log(item)
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        color: "#252C58",
                                        fontWeight: 700,
                                        textAlign: "center"
                                    }} ><small>View <FaArrowAltCircleRight /></small> </td>
                            </tr>
                        </>
                    })}
                </tbody>
            </table>

            {currentData.length < 1 &&
                <div style={{
                    // backgroundColor: "red",
                    width: "100%",
                    marginTop: 90,
                    marginBottom: 90,
                }} >
                    <center>
                        <b>No record found</b>
                    </center>
                </div>
            }

            <Divider />
            <div className="pagination-controls" style={{ padding: 20 }} >
                <label>
                    <small> Rows per page:</small>
                    <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                        <option value={25}>25</option>
                        <option value={30}>30</option>
                    </select>
                </label>
                <div style={{
                    justifyContent: "center", alignItems: "center", display: "flex"
                }} >
                    <small style={{ marginRight: 40 }} >
                        {currentPage} of {totalPages}
                    </small>
                    <span style={{ marginRight: 40, cursor: currentPage === 1 ? "no-drop" : "pointer", opacity: currentPage === 1 ? 0.4 : 1 }} onClick={() => handleChangePage(Math.max(currentPage - 1, 1))}>
                        <BiArrowToLeft />
                    </span>
                    <span onClick={() => handleChangePage(Math.min(currentPage + 1, totalPages))} style={{ marginRight: 40, cursor: currentPage === totalPages ? "no-drop" : "pointer", opacity: currentPage === totalPages ? 0.4 : 1 }} >
                        <BiArrowToRight />
                    </span>

                </div>
            </div>
        </div>
    );
};

export default Table;