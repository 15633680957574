import React, { useState } from 'react'
import Sidebar from '../../Components/Sidebar'
import DashHeader from '../../Components/DashHeader'
import { PiExportBold } from "react-icons/pi";
import { BsFileBarGraphFill } from "react-icons/bs";
import { BiSolidReceipt, BiSort } from "react-icons/bi";
import { IoIosPricetags } from "react-icons/io";
import { FaDeleteLeft, FaUserPlus } from "react-icons/fa6";
import Chart from 'react-apexcharts';
import { IoFilterOutline } from "react-icons/io5";
import { Backdrop, Box, CircularProgress, Divider, Fade, Menu, MenuItem, Modal, colors } from '@mui/material';
import { connect } from 'react-redux';
import { AdminDeleteInvoice, deleteInvoice, fetchAllInvoicesAdmin, fetchAllInvoicesBySalesRep } from '../../service/supabase-service';
import { Invoice_Product, Saved_invoices, View_invoice } from '../../redux/state/action';
import { Notify, NumberWithCommas, formatDate } from '../../utils';
import { FaArrowAltCircleRight, FaPrint, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import AdminSidebar from '../../Components/admin-sidebar';
import Card1 from '../../Components/Card1';
import img1 from '../../images/c1.png'
import img2 from '../../images/c2.png'
import img3 from '../../images/c3.png'
import img4 from '../../images/c4.png'
import img5 from '../../images/c5.png'
import img6 from '../../images/c6.png'
import Card2 from '../../Components/Card2';
import i1 from '../../images/i1.png'
import i2 from '../../images/i2.png'
import i3 from '../../images/i3.png'
import i4 from '../../images/i4.png'
import q1 from '../../images/q1.png'
import q2 from '../../images/q2.png'
import Table from './components/sales-table';




const Salesmgt = ({
    appState, disp_savedInvoice, disp_invoice_products, disp_view_invoice
}) => {
    const User = appState.User;
    const InvoiceProducts = appState.AllInvoiceProducts
    const SavedInvoices = appState.SavedInvoices;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // const SavedInvoices = []

    const [loading, setloading] = React.useState(false)
    const [amount_Array, setamount_Array] = React.useState([])
    const navigate = useNavigate();
    const [FilterSales, SetFilterSales] = React.useState("WOJI")
    const [sortTableTitle, setsortTableTitle] = React.useState("Today")
    const [TableData, setTableData] = React.useState(SavedInvoices.filter(e => e.branch == FilterSales))



    const style = {
        position: 'absolute',
        top: '3%',
        left: '25%',
        // transform: 'translate(-50%, -50%)',
        width: "50%",
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 4,
        maxHeight: "90%",
        p: 4,
        overflow: "auto",
    };


    function FetchInvoices() {
        setloading(true)
        fetchAllInvoicesAdmin()
            .then(response => {
                console.log(response)
                setloading(false)
                if (!response.error) {
                    disp_savedInvoice(response.data)
                } else {
                    alert(response.error.message)
                    disp_savedInvoice([])
                }

            })
            .catch(error => {
                setloading(false)
                disp_savedInvoice([])
            })
    }


    React.useEffect(() => {
        FetchInvoices()

        if (SavedInvoices.length > 0) {
            let amountArray = []
            for (let i = 0; i < SavedInvoices.length; i++) {
                const element = SavedInvoices[i];
                amountArray.push(element.amount)

            }
            setamount_Array(amountArray)
        }
    }, [])


    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const day = String(today.getDate()).padStart(2, '0');
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const seconds = String(today.getSeconds()).padStart(2, '0');
    const milliseconds = String(today.getMilliseconds()).padStart(3, '0');

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;

    let Filter = SavedInvoices.filter(e => e.created_at.split("T")[0] == formattedDate.split("T")[0])

    // const TotalProductAmount = SavedInvoices.reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)) + item.vat, 0)

    // today's sale
    const TodaysTotalProductAmount = SavedInvoices.filter(e => e.payData != null).filter(e => e.branch == "WOJI").filter(e => e.created_at.split("T")[0] == formattedDate.split("T")[0]).reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)

    // total sales
    const TotalProductAmount = SavedInvoices.filter(e => e.payData != null).filter(e => e.branch == "WOJI").reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)


    // today's service sale
    const TodaysTotalServiceAmount = SavedInvoices.filter(e => e.payData != null).filter(e => e.type == "SERVICE").filter(e => e.branch == "WOJI").filter(e => e.created_at.split("T")[0] == formattedDate.split("T")[0]).reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)

    // total service sales
    const TotalServiceAmount = SavedInvoices.filter(e => e.payData != null).filter(e => e.type == "SERVICE").filter(e => e.branch == "WOJI").reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)




    // today's sale
    const TodaysTotalProductAmountWOJI = SavedInvoices.filter(e => e.payData != null).filter(e => e.type == "PRODUCT").filter(e => e.branch == "WOJI").filter(e => e.created_at.split("T")[0] == formattedDate.split("T")[0]).reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)

    // total sales
    const TotalProductAmountWOJI = SavedInvoices.filter(e => e.payData != null).filter(e => e.type == "PRODUCT").filter(e => e.branch == "WOJI").reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)


    // today's service sale
    const TodaysTotalServiceAmountWOJI = SavedInvoices.filter(e => e.payData != null).filter(e => e.type == "SERVICE").filter(e => e.branch == "WOJI").filter(e => e.created_at.split("T")[0] == formattedDate.split("T")[0]).reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)

    // total service sales
    const TotalServiceAmountWOJI = SavedInvoices.filter(e => e.payData != null).filter(e => e.type == "SERVICE").filter(e => e.branch == "WOJI").reduce((acc, item) => (acc + parseInt(item.payData.productCostPlusVat)), 0)



    const ITEM_HEIGHT = 48;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openX = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseX = () => {
        setAnchorEl(null);
    };

    // sort table
    let date = new Date();
    function SortTable(type) {
        function formatDateWithOffset(date) {
            const pad = (num, size = 2) => String(num).padStart(size, '0');
            const year = date.getUTCFullYear();
            const month = pad(date.getUTCMonth() + 1);
            const day = pad(date.getUTCDate());
            const hours = pad(date.getUTCHours());
            const minutes = pad(date.getUTCMinutes());
            const seconds = pad(date.getUTCSeconds());
            const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0') + '000'; // pad to microseconds
            const timezoneOffset = '+00:00';

            return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneOffset}`;
        }


        function getDateWithOffset(offset) {
            date.setDate(date.getDate() + offset);
            return formatDateWithOffset(date);
        }

        let today = new Date(getDateWithOffset(0)).toISOString().split('T')[0];
        let yesterday = new Date(getDateWithOffset(-1)).toISOString().split('T')[0];
        let dayBeforeYesterday = new Date(getDateWithOffset(-2)).toISOString().split('T')[0];

        // ===

        let Todaysort = SavedInvoices.filter(e => new Date(e.created_at).toISOString().split('T')[0] == today)
        let yesterdaysort = SavedInvoices.filter(e => new Date(e.created_at).toISOString().split('T')[0] == yesterday)
        let dayBeforeYesterdaysort = SavedInvoices.filter(e => new Date(e.created_at).toISOString().split('T')[0] == dayBeforeYesterday)


        if (type == "Daybeforeesterday") { // day before yesterday
            setTableData(dayBeforeYesterdaysort)
        } else if (type == "Yesterday") { // yesterday
            setTableData(yesterdaysort)
        } else if (type == "Today") { // today
            setTableData(Todaysort)
        } else if (type == "All") {
            setTableData(SavedInvoices && SavedInvoices.length > 0 ? SavedInvoices.filter(e => e.branch == FilterSales) : [])
        } else if (type == "Sold") {
            // console.log(SavedInvoices[0])
            setTableData(SavedInvoices && SavedInvoices.length > 0 ? SavedInvoices.filter(e => e.branch == FilterSales && e.paid == true) : [])

        } else if (type == "Not sold") {
            setTableData(SavedInvoices && SavedInvoices.length > 0 ? SavedInvoices.filter(e => e.branch == FilterSales && e.paid != true) : [])

        } else if (type == "All Products") {
            setTableData(SavedInvoices && SavedInvoices.length > 0 ? SavedInvoices.filter(e => e.branch == FilterSales && e.type == "PRODUCT") : [])

        } else if (type == "All Services") {

            setTableData(SavedInvoices && SavedInvoices.length > 0 ? SavedInvoices.filter(e => e.branch == FilterSales && e.type == "SERVICE") : [])

        }

        handleCloseX()
    }


    function getYesterdayDateFormatted() {
        const date = new Date();
        date.setDate(date.getDate() - 1);
    
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
    
        const dayWithSuffix = day + getDaySuffix(day);
    
        return `${dayWithSuffix} ${month}`;
      }
    
      function getDaySuffix(day) {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
        switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
      }

      



    return (
        <div>
            {console.log(NumberWithCommas(TodaysTotalProductAmount))}

            {loading && <div style={{
                position: "fixed",
                height: "100%",
                width: "100%",
                left: 0,
                top: 0,
                backgroundColor: "rgb(0,0,0,0.8)",
                zIndex: 100,
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column"
            }} >
                <CircularProgress />
                <spam style={{ color: "white" }} >Please wait.....</spam>
            </div>}

            <section className='main-dash'>

                <AdminSidebar />

                <div className='main'>
                    <DashHeader User={User} />



                    <div className="sales-summary">

                        <div className="sales-o">

                            <div className="sales1">

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: 30

                                }} >
                                    <h2>WOJI Branch</h2>

                                    <span onClick={() => {
                                        navigate("/woji-salse-management")
                                    }} className='export' style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        borderRadius: 6
                                    }}>
                                        {/* <PiExportBold className='e-i' /> */}
                                        See GRA branch

                                    </span>

                                </div>



                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: 30

                                }} >
                                    <h2 style={{
                                        flex: 1,
                                        justifyContent: "center",
                                    }} > Products</h2>
                                    <h2 style={{
                                        flex: 1,
                                        justifyContent: "center",
                                    }} >Services</h2>
                                </div>
                                <div className='s-c'>

                                    <div className='s1'>
                                        <Card1 img={img1} price={`₦ ${NumberWithCommas(TodaysTotalProductAmountWOJI)}`} info='Today' />

                                    </div>

                                    <div className='s1'>
                                        <Card1 img={img2} price={`₦${NumberWithCommas(TotalProductAmountWOJI)}`} info='Total' />

                                    </div>

                                    <div className='s1'>
                                        <Card1 img={img3} price={`₦${NumberWithCommas(TodaysTotalServiceAmountWOJI)}`} info='Today' />

                                    </div>

                                    <div className='s1'>
                                        <Card1 img={img4} price={`₦${NumberWithCommas(TotalServiceAmountWOJI)}`} info='Total' />

                                    </div>

                                </div>
                            </div>

                        </div>


                        <div className="inventory">
                            <div className="sales1">
                                <h2>Summary</h2>

                                <div className="i-c">
                                    <div className="i-c1">
                                        <Card2 img={i1} numb={`₦${NumberWithCommas(TotalProductAmountWOJI)}`} info='Products' />
                                    </div>
                                    <div className="i-c1">
                                        <Card2 img={i2} numb={`₦${NumberWithCommas(TotalServiceAmountWOJI)}`} info='Services' />
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>



                    <div className="sales-summary">

                        <div className="sales-o">

                            <div className="sales1">
                                <h2>Sales Record
                                    <span>
                                        {/* sort */}
                                        <div>

                                            <div style={{
                                                marginRight: 50,
                                                cursor: "pointer",
                                            }}>
                                                <span
                                                    onClick={handleClick}
                                                    className="fil">
                                                    <BiSort style={{ marginRight: "20px" }} />
                                                    {sortTableTitle}
                                                </span>
                                                <Menu
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={openX}
                                                    onClose={handleCloseX}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    }}
                                                >
                                                    <MenuItem selected={'Pyxis'} onClick={() => { SortTable("Daybeforeesterday"); setsortTableTitle(getYesterdayDateFormatted()) }}> {
                                                        getYesterdayDateFormatted()

                                                    } </MenuItem>
                                                    <MenuItem selected={true} onClick={() => { SortTable("Yesterday"); setsortTableTitle("Yesterday") }}> Yesterday </MenuItem>
                                                    <MenuItem selected={'Pyxis'} onClick={() => { SortTable("Today"); setsortTableTitle("Today") }}> Today </MenuItem>
                                                    <MenuItem selected={'Pyxis'} onClick={() => { SortTable("All"); setsortTableTitle("All") }}> All </MenuItem>
                                                    <MenuItem selected={'Pyxis'} onClick={() => { SortTable("Sold"); setsortTableTitle("All Sold") }}> Sold </MenuItem>
                                                    <MenuItem selected={'Pyxis'} onClick={() => { SortTable("Not sold"); setsortTableTitle("Not Sold") }}> Not sold </MenuItem>
                                                    <MenuItem selected={'Pyxis'} onClick={() => { SortTable("All Products"); setsortTableTitle("All Products") }}> All Products </MenuItem>
                                                    <MenuItem selected={'Pyxis'} onClick={() => { SortTable("All Services"); setsortTableTitle("All Services") }}>All Services </MenuItem>
                                                </Menu>
                                            </div>

                                        </div>
                                    </span></h2>



                                <Table Data={TableData ? TableData : []} />

                            </div>

                        </div>



                        <div className="inventory">
                            <div className="sales1">
                                <h2>Low Quantity  Stock <span>See All</span></h2>

                                <div className="row">
                                    <div className="row1">
                                        <img src={q1} alt="" />
                                        <div className='quan'>
                                            <h6>Tata salt</h6>
                                            <p>Remaining Quantity : <span>10 Packet</span></p>
                                        </div>
                                        <button>low</button>
                                    </div>

                                    <div className="row1">
                                        <img src={q2} alt="" />
                                        <div className='quan'>
                                            <h6>Lays</h6>
                                            <p>Remaining Quantity : <span>10 Packet</span></p>
                                        </div>
                                        <button>low</button>
                                    </div>

                                    <div className="row1">
                                        <img src={q1} alt="" />
                                        <div className='quan'>
                                            <h6>Tata salt</h6>
                                            <p>Remaining Quantity : <span>10 Packet</span></p>
                                        </div>
                                        <button>low</button>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </div>


                </div>


            </section>



            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // backgroundColor: "darkred",
                            width: "100%",
                            // height: 70,
                            padding: 10
                        }}>
                            <section className='invoice' style={{ padding: 20, backgroundColor: "lightgrey", }} id="pdf-content">

                                <Divider style={{ marginTop: 0 }} />
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: 20,
                                    marginBottom: 6,
                                }}>
                                    <b style={{ color: "#000", fontSize: 14 }} >Invoice No.:</b>
                                    <p style={{ color: "#000", fontSize: 13 }} >{InvoiceProducts.invoiceID}</p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginBottom: 6,
                                }}>
                                    <b style={{ color: "#000", fontSize: 14 }} >Date:</b>
                                    <p style={{ color: "#000", fontSize: 13 }} >{formatDate(InvoiceProducts.created_at)}</p>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginBottom: 6,
                                }}>
                                    <b style={{ color: "#000", fontSize: 14 }} >Sales Person:</b>
                                    <p style={{ color: "#000", fontSize: 13 }} >{User.name}</p>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginBottom: 6,
                                }}>
                                    <b style={{ color: "#000", fontSize: 14 }} >Branch</b>
                                    <p style={{ color: "#000", fontSize: 13 }} >{InvoiceProducts.branch}</p>
                                </div>


                                <table className='table1' style={{ marginTop: 30 }} >
                                    <tr className='tr1'>
                                        <th style={{
                                            fontSize: 11,
                                            fontWeight: 500,
                                            // backgroundColor: "red",
                                            width: "16%",
                                            color: "#000"
                                        }}>S/N</th>
                                        <th style={{
                                            fontSize: 11,
                                            fontWeight: 500,
                                            // backgroundColor: "#fff",
                                            width: "90%",
                                            color: "#000"
                                        }}>PRODUCT</th>
                                        <th style={{
                                            fontSize: 11,
                                            fontWeight: 500,
                                            // backgroundColor: "#000",
                                            width: "30%",
                                            color: "#000"
                                        }}>PRICE</th>
                                        <th style={{
                                            fontSize: 11,
                                            fontWeight: 500,
                                            // backgroundColor: "red",
                                            width: "30%",
                                            color: "#000"
                                        }}>QTY.</th>
                                        <th style={{
                                            fontSize: 11,
                                            fontWeight: 500,
                                            // backgroundColor: "#000",
                                            width: "30%",
                                            color: "#000"
                                        }}>SUBTOTAL</th>
                                    </tr>
                                    {
                                        InvoiceProducts && InvoiceProducts.product && InvoiceProducts.product.map((items, index) => {
                                            return <tr key={index} style={{ marginBottom: 5 }} >
                                                <td style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    // backgroundColor: "red",
                                                    width: "16%",
                                                    color: "#000"
                                                }}>{index + 1}</td>
                                                <td style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    color: "#000",
                                                    marginRight: 8,
                                                    width: "90%",
                                                    fontWeight: 700,
                                                }}>{items.name}</td>
                                                <td style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    // backgroundColor: "red",
                                                    width: "30%",
                                                    textAlign: "center",
                                                    color: "#000"
                                                }}> {NumberWithCommas(`₦{items.metaData.price}`)} </td>
                                                <td style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    // backgroundColor: "red",
                                                    width: "30%",
                                                    textAlign: "center",
                                                    color: "#000"
                                                }}>{items.qty}</td>
                                                <td style={{
                                                    fontSize: 11,
                                                    fontWeight: 500,
                                                    // backgroundColor: "red",
                                                    width: "30%",
                                                    textAlign: "center",
                                                    color: "#000"
                                                }}> {NumberWithCommas(`₦₦{items.metaData.price * items.qty}`)} </td>
                                            </tr>
                                        })
                                    }

                                </table>

                                <Divider style={{ marginTop: 20 }} />

                                {InvoiceProducts.paid && <>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "space-between",
                                        marginTop: 20,
                                        marginBottom: 6,
                                    }}>
                                        <b style={{ color: "#000", fontSize: 10 }} >Cost of product</b>
                                        <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} > ₦{NumberWithCommas(InvoiceProducts.payData.productCost)}</p>
                                    </div>
                                    {InvoiceProducts.payData.discount_amount &&
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            marginTop: 20,
                                            marginBottom: 6,
                                        }}>
                                            <b style={{ color: "#000", fontSize: 10 }} >Discount</b>
                                            <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} > ₦{NumberWithCommas(InvoiceProducts.payData.discount_amount)}</p>
                                        </div>
                                    }

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "space-between",
                                        marginTop: 20,
                                        marginBottom: 6,
                                    }}>
                                        <b style={{ color: "#000", fontSize: 10 }} >VAT</b>
                                        <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} >₦{NumberWithCommas(InvoiceProducts.payData.taxAmount)}</p>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "space-between",
                                        marginTop: 20,
                                        marginBottom: 6,
                                    }}>
                                        <b style={{ color: "#000", fontSize: 10 }} >Total</b>
                                        <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} >₦{NumberWithCommas(InvoiceProducts.payData.productCostPlusVat)}</p>
                                    </div>


                                    <Divider style={{ marginTop: 20 }} />

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginTop: 20,
                                        marginBottom: 6,
                                    }}>
                                        <b style={{ color: "#000", fontSize: 14 }} >Payment method</b>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        // justifyContent: "space-between",
                                        marginTop: 20,
                                        marginBottom: 6,
                                    }}>
                                        <b style={{ color: "#000", fontSize: 10 }} >{InvoiceProducts.payData.paymentMetheod}: </b>
                                        <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} > ₦{NumberWithCommas(InvoiceProducts.payData.amountToPay)}</p>
                                    </div>


                                    {InvoiceProducts.payData.complimentaryMethod != "SELECT" && <>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            marginTop: 20,
                                            marginBottom: 6,
                                        }}>
                                            <b style={{ color: "#000", fontSize: 10 }} >{InvoiceProducts.payData.complimentaryMethod}: </b>
                                            <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} >₦{NumberWithCommas(InvoiceProducts.payData.productCostPlusVat - InvoiceProducts.payData.amountToPay)}</p>
                                        </div>
                                    </>}



                                    {InvoiceProducts.customerphone && <>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            marginTop: 20,
                                            marginBottom: 6,
                                        }}>
                                            <b style={{ color: "#000", fontSize: 10 }} >Customer phone: </b>
                                            <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} >{InvoiceProducts.customerphone}</p>
                                        </div>
                                    </>}
                                    {InvoiceProducts.marketerid ? <>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            marginTop: 20,
                                            marginBottom: 6,
                                        }}>
                                            <b style={{ color: "#000", fontSize: 10 }} >Customer type </b>
                                            <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} >Referred</p>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            marginTop: 20,
                                            marginBottom: 6,
                                        }}>
                                            <b style={{ color: "#000", fontSize: 10 }} >Marketer: </b>
                                            <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} >{InvoiceProducts.marketerid}</p>
                                        </div>
                                    </> : <>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            // justifyContent: "space-between",
                                            marginTop: 20,
                                            marginBottom: 6,
                                        }}>
                                            <b style={{ color: "#000", fontSize: 10 }} >Customer type </b>
                                            <p style={{ color: "#000", fontSize: 10, marginLeft: 10 }} >Walk-in customer</p>
                                        </div>
                                    </>}

                                </>
                                }


                            </section>

                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 20,
                            backgroundColor: "#fff",
                            width: "100%", height: 70,
                            padding: 10
                        }}>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }} >

                                <p
                                    onClick={() => {
                                        handleClose()
                                    }}
                                    style={{
                                        backgroundColor: '#fff',
                                        color: "#FA5A7D",
                                        padding: 5,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        // marginLeft: 10,
                                        borderRadius: 6,
                                        cursor: "pointer",
                                        fontSize: 13,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: 500
                                    }}
                                >
                                    Close   <FaTimes style={{ marginLeft: 2 }} />
                                </p>


                                <p
                                    onClick={() => {
                                        handleClose()
                                        setloading(true)
                                        AdminDeleteInvoice(InvoiceProducts.invoiceID)
                                            .then(responseX => {
                                                fetchAllInvoicesAdmin()
                                                    .then(response => {
                                                        disp_savedInvoice(response.data)
                                                        Notify("Quote deleted successfully")
                                                        setTimeout(() => {
                                                            setloading(false)
                                                            disp_view_invoice(null)
                                                        }, 2000);

                                                    })
                                                    .catch(error => {
                                                        setloading(false)
                                                    })

                                            })
                                            .catch(error => {
                                                setloading(false)

                                            })
                                    }}
                                    style={{
                                        backgroundColor: '#fff', color: "#FA5A7D",
                                        padding: 5,
                                        paddingRight: 10,
                                        paddingLeft: 10,
                                        marginLeft: 10,
                                        borderRadius: 6,
                                        cursor: "pointer",
                                        fontSize: 13,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontWeight: 500
                                    }}
                                >
                                    Delete   <FaDeleteLeft style={{ marginLeft: 2 }} />
                                </p>


                            </div>
                        </div>



                    </Box>
                </Fade>
            </Modal>




        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        appState: state.user,
    };
};


const mapDispatchToProps = (dispatch, encoded) => {
    return {
        disp_savedInvoice: (payload) => dispatch(Saved_invoices(payload)),
        disp_invoice_products: (payload) => dispatch(Invoice_Product(payload)),
        disp_view_invoice: (payload) => dispatch(View_invoice(payload)),

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Salesmgt);
